import React from 'react';
import Header from './Component/Header';
import HomePage from './Component/HomePage';
import Footer from './Component/Footer';
import Login from './Component/Login';
import Register from './Component/Register';
import WalletPage from './Component/WalletPage';
import Tradepage from './Component/Tradepage';
import Deposithistory from './Component/Deposithistory';
import WithdrawalHistory from './Component/WithdrawalHistory';
import OpenOrder from './Component/OpenOrder';
import CloseOrder from './Component/CloseOrder';
import ProfilePage from './Component/ProfilePage';
import INRDeposithistory from './Component/INRDeposithistory';
import  {AuthProvider}  from './Component/AuthContext'; // Adjust the path
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Import the container
import 'react-toastify/dist/ReactToastify.css'; // Import the styles
function App() {
  return (
    <AuthProvider>

    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/spot/:symbol" element={<Tradepage />} />
          <Route path="/Deposithistory" element={<Deposithistory />} />
          <Route path="/WithdrawalHistory" element={<WithdrawalHistory />} />
          <Route path="/OpenOrder" element={<OpenOrder />} />
          <Route path="/CloseOrder" element={<CloseOrder />} />
          <Route path="/INRDeposithistory" element={<INRDeposithistory />} />
          <Route path="/profile" element={<ProfilePage />} />

        </Routes>
        <Footer />
        <ToastContainer /> {/* Place the ToastContainer at the root */}
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;

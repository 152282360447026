import React, { useState, useEffect } from "react";
import { getwallet, createwallets, withdraw, upload, sendotp } from "../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/WalletPage.css";
import { useNavigate } from "react-router-dom";

const WalletPage = () => {
  const [walletData, setWalletData] = useState([]);
  const [Totalbalance, setTotalbalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showDepositAddress, setShowDepositAddress] = useState(null);
  const [showWithdrawalForm, setShowWithdrawalForm] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State to show OTP popup
  const [otp, setOtp] = useState(""); // State to manage OTP input
  const [withdrawalData, setWithdrawalData] = useState({
    address: "",
    amount: "",
    symbol: "",
    otp: "",
  });
  const [amount, setAmount] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  // Toggle Sidebar
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Fetch Wallet Data
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await getwallet();
        setTotalbalance(response.total_balance_in_inr);
        setWalletData(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        navigate("/login");
      }
    };

    fetchWallet();
  }, []);

  // Copy Wallet Address
  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Address copied to clipboard!");
  };

  // Toggle Deposit or Withdrawal Views
  const handleToggleCollapse = (walletId, action, symbol) => {
    if (action === "deposit") {
      setShowDepositAddress((prev) => (prev === walletId ? null : walletId));
      setShowWithdrawalForm(null);
    } else if (action === "withdrawal") {
      setShowWithdrawalForm((prev) => (prev === walletId ? null : walletId));
      setShowDepositAddress(null);
      setWithdrawalData((prevState) => ({
        ...prevState,
        symbol,
      }));
    }
  };

  // Handle Withdrawal Form Changes
  const handleWithdrawalChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Submit Withdrawal Request
  const handleWithdrawSubmit = async () => {
    if (
      !withdrawalData.address ||
      !withdrawalData.amount ||
      !withdrawalData.symbol
    ) {
      toast.error("Please provide address, amount, and symbol.");
      return;
    }

    try {
      const response = await withdraw(withdrawalData);
      toast.success(response.message);

      // Reset form and refresh wallet data
      setWithdrawalData({ address: "", amount: "", symbol: "", otp: "" });
      setShowWithdrawalForm(null);
      setShowPopup(false)
      const updatedWallet = await getwallet();
      setTotalbalance(updatedWallet.total_balance_in_inr);
      setWalletData(updatedWallet.data);
    } catch (error) {
      toast.error(`Withdrawal failed: ${error.message}`);
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!amount || !utrNumber || !image) {
      setError("Please fill out all fields and select an image.");
      return;
    }

    setLoading(true); // Set uploading state to true

    const formData = new FormData();
    formData.append("file", image);
    formData.append("amount", amount);
    formData.append("utr_number", utrNumber);

    try {
      const response = await upload(formData);
      const result = await response.json();
      console.log("result", result);
      setShowPopup(false); // Close the popup after successful verification

      // Check if the response indicates success
      if (result.success) {
        toast.success(result.message); // Show success message
        setAmount(""); // Clear the form fields
        setUtrNumber("");
        setImage(null);
      } else {
        // Handle the case where there's an error in the response
        toast.error(
          result.message || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      setShowPopup(false); // Close the popup after successful verification
      console.error("Error uploading data:", error);
      setError("Error uploading data. Please try again.");
    } finally {
      setShowPopup(false); // Close the popup after successful verification
      setLoading(false); // Set uploading state to false after API call
    }
  };

  const handleVerifyOtp = async () => {
    try {
      // Call the verifyUserEmail API with the OTP
      const verifyResponse = await sendotp();
      if (verifyResponse && verifyResponse.error === false) {
        toast.success("Email verified successfully!");
        setShowPopup(true); // Close the popup after successful verification
      } else {
        toast.error(verifyResponse.message || "Verification failed!");
      }
    } catch (err) {
      console.error("Error verifying email:", err);
      toast.error(
        "An error occurred while verifying the email. Please try again."
      );
    }
  };

  // Loading State
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="wallet-container">
      {/* Balance Card */}
      <div className="balance-card">
        <div className="balance-card-header">
          <button className="sidebar-button" onClick={toggleSidebar}>
            Sidebar
          </button>
          <p className="balance-amount" id="totalBalance">
            Total Balance ₹ {Totalbalance}
          </p>
        </div>

        {isSidebarOpen && (
          <div className="sidebar">
            <button
              className="sidebar-item"
              onClick={() => navigate("/Deposithistory")}
            >
              Deposit History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/INRDeposithistory")}
            >
              INR Deposit History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/WithdrawalHistory")}
            >
              Withdrawal History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/OpenOrder")}
            >
              Open Order
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/CloseOrder")}
            >
              Close Order
            </button>
          </div>
        )}
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Wallet Data Table */}
      {walletData.length > 0 ? (
        <div className="table-container">
          <table className="wallet-table">
            <thead>
              <tr>
                <th></th>
                <th>Currency</th>
                <th>Balance</th>
                <th>Locked</th>
                <th>Available</th>
                <th>Total in INR</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {walletData.map((wallet) => (
                <React.Fragment key={wallet._id}>
                  <tr>
                    <td>
                      <img
                        src={wallet.currencyDetails.icon}
                        alt={wallet.currencyDetails.symbol}
                        className="currency-icon"
                      />
                    </td>
                    <td>{wallet.currencyDetails.symbol}</td>
                    <td>{(wallet.balance ?? 0).toFixed(6)}</td>
                    <td>{(wallet.locked ?? 0).toFixed(6)}</td>
                    <td>{(wallet.balance + wallet.locked ?? 0).toFixed(6)}</td>
                    <td>{(wallet.balance_in_inr ?? 0).toFixed(6)}</td>
                    <td className="actions">
                      {wallet.wallet_address ? (
                        <>
                          {wallet.currencyDetails.is_deposite === 1 ? (
                            <button
                              className="action-button deposit"
                              onClick={() =>
                                handleToggleCollapse(wallet._id, "deposit")
                              }
                            >
                              Deposit
                            </button>
                          ) : (
                            <button
                              className="action-button deposit disabled"
                              disabled
                            >
                              Deposit
                            </button>
                          )}
                          {wallet.currencyDetails.is_withdrawal === 1 ? (
                            <button
                              className="action-button withdrawal"
                              onClick={() =>
                                handleToggleCollapse(
                                  wallet._id,
                                  "withdrawal",
                                  wallet.currencyDetails.symbol
                                )
                              }
                            >
                              Withdrawal
                            </button>
                          ) : (
                            <button
                              className="action-button deposit disabled"
                              disabled
                            >
                              Withdrawal
                            </button>
                          )}
                          <button
                            className="action-button trade"
                            onClick={() => {
                              if (wallet.currencyDetails.symbol === "RUBY") {
                                navigate(
                                  `/spot/RUBY-TRON`
                                );
                              } else {
                                navigate(
                                  `/spot/${wallet.currencyDetails.symbol}-RUBY`
                                );
                              }
                             
                            }}
                          >
                            Trade
                          </button>
                        </>
                      ) : (
                        <button
                          className="action-button create"
                          onClick={async () => {
                            try {
                              const response = await createwallets(
                                wallet.currencyDetails.symbol
                              );
                              toast.success(
                                `Wallet for ${wallet.currencyDetails.symbol} created successfully!`
                              );

                              const updatedWallet = await getwallet();
                              setTotalbalance(
                                updatedWallet.total_balance_in_inr
                              );
                              setWalletData(updatedWallet.data);
                            } catch (error) {
                              toast.error(
                                `Failed to create wallet for ${wallet.currencyDetails.symbol}: ${error.message}`
                              );
                            }
                          }}
                        >
                          Create
                        </button>
                      )}
                    </td>
                  </tr>

                  {/* Deposit Address */}
                  {showDepositAddress === wallet._id &&
                    (wallet.wallet_type === "INR" ? (
                      <>
                        <tr>
                          <td colSpan="7" className="collapsible-box">
                            {" "}
                            <div className="qr-code-section">
                              <h3>Scan QR Code for Payment</h3>
                              <img
                                src={`http://143.244.135.250:3006/api/pay/qr.png`}
                                alt="QR Code"
                                className="qr-code-image"
                              />
                            </div>
                            <div className="deposit-form">
                              <h3>Upload Deposit Details</h3>
                              <form onSubmit={handleUpload}>
                                <div>
                                  <label htmlFor="amount">Amount:</label>
                                  <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required
                                  />
                                </div>
                                <div>
                                  <label htmlFor="utrNumber">UTR Number:</label>
                                  <input
                                    type="text"
                                    id="utrNumber"
                                    value={utrNumber}
                                    onChange={(e) =>
                                      setUtrNumber(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                <div>
                                  <label htmlFor="image">Upload Image:</label>
                                  <input
                                    type="file"
                                    id="image"
                                    onChange={(e) =>
                                      setImage(e.target.files[0])
                                    }
                                    required
                                  />
                                </div>
                                <button type="submit" className="upload-button">
                                  Upload
                                </button>
                              </form>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="7" className="collapsible-box">
                          <div className="deposit-address">
                            <p>
                              Wallet Address: {wallet.wallet_address}
                              <button
                                className="copy-button"
                                onClick={() =>
                                  handleCopy(wallet.wallet_address)
                                }
                              >
                                Copy
                              </button>
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}

                  {/* Withdrawal Form */}
                  {showWithdrawalForm === wallet._id && (
                    <tr>
                      <td colSpan="7" className="collapsible-box">
                        <div className="withdrawal-form">
                          <label htmlFor="address">Withdrawal Address:</label>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            value={withdrawalData.address}
                            onChange={handleWithdrawalChange}
                            placeholder="Enter withdrawal address"
                          />
                          <label htmlFor="amount">Amount:</label>
                          <input
                            type="number"
                            id="amount"
                            name="amount"
                            value={withdrawalData.amount}
                            onChange={handleWithdrawalChange}
                            placeholder="Enter withdrawal amount"
                          />
                          <label htmlFor="symbol">Currency Symbol:</label>
                          <input
                            type="text"
                            id="symbol"
                            name="symbol"
                            value={withdrawalData.symbol || ""}
                            disabled
                          />
                          <button
                            className="submit-button"
                            onClick={() => handleVerifyOtp()}
                          >
                            Withdraw
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No wallet data available.</p>
      )}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h3>Verify Your Email</h3>
            <input
              type="number"
              id="otp"
              name="otp"
              value={withdrawalData.otp}
              onChange={handleWithdrawalChange}
              placeholder="Enter Otp"
            />
            <button onClick={handleWithdrawSubmit} className="verify-button">
              Verify OTP
            </button>
            <button
              onClick={() => setShowPopup(false)}
              className="close-button"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletPage;

import React, { useState, useEffect } from "react";
import "../style/BuySellPage.css"; // Adjust the path as per your project structure
import { useParams } from "react-router-dom";
import { buyOrder, sellOrder, getbalance } from "../api"; // Adjust the path according to your file structure
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BuySellPage = ({ selectedOrder }) => {
  const [volume, setBuyAmount] = useState("");
  const [price, setPrice] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [buyBalance, setBuyBalance] = useState(null);
  const [sellBalance, setSellBalance] = useState(null);
  const { symbol } = useParams();
  const [baseCurrency, quoteCurrency] = symbol ? symbol.split("-") : ["", ""];
  const [loading, setLoading] = useState(false);
  const [sellTotal, setsellTotal] = useState(); // Set initial state to empty string
  const [buyTotal, setbuyTotal] = useState(); // Set initial state to empty string
  console.log(selectedOrder)

  useEffect(() => {
   
    if (selectedOrder && selectedOrder.side) {
      if (selectedOrder.side === "ask") {
        setBuyAmount(selectedOrder.ask?.quantity.toFixed(6) || 0); // Use default value if `quantity` is undefined
        setPrice(selectedOrder.ask?.price || 0); // Use default value if `price` is undefined
        setbuyTotal((selectedOrder.ask?.quantity*selectedOrder.ask?.price).toFixed(6) )
      } else if (selectedOrder.side === "bid") {
        setSellAmount(selectedOrder.bid?.quantity.toFixed(6) || 0); // Use default value if `quantity` is undefined
        setSellPrice(selectedOrder.bid?.price || 0); // Use default value if `price` is undefined
        setsellTotal((selectedOrder.bid?.quantity*selectedOrder.bid?.price).toFixed(6) )

      }
    }
  

  }, [selectedOrder]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await getbalance(symbol);
        if (response && response.data) {
          const buyWallet = response.data.find(
            (wallet) => wallet.wallet_type === quoteCurrency
          );
          const sellWallet = response.data.find(
            (wallet) => wallet.wallet_type === baseCurrency
          );
          setBuyBalance(buyWallet);
          setSellBalance(sellWallet);

          const latestPrice = response.getlatestprice
            ? response.getlatestprice[0]
            : null;
          if (latestPrice) {
            setPrice(latestPrice.raw_price);
            setSellPrice(latestPrice.raw_price);
          }
        }
      } catch (error) {}
    };

    fetchBalance();
  }, [symbol, baseCurrency, quoteCurrency]);

  const handleBuySubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await buyOrder(symbol, price, volume);
      toast.success(response.message);
      window.location.reload();
      setLoading(false);
    } catch (error) {
      toast.error("Failed to place buy order.");
      setLoading(false);
    }
  };

  const handleSellSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await sellOrder(symbol, sellPrice, sellAmount);
      toast.success(response.message);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Failed to place sell order.");
      setLoading(false);
    }
  };

  const handlesellTotalChange = (e) => {
    const totalValue = e.target.value;
    if (price > 0) {
      const calculatedAmount = totalValue / price;
      setSellAmount(calculatedAmount.toFixed(4));
    } else {
      setSellAmount(0);
    }
    setsellTotal(totalValue); // Update the total field
  };
  const handlebuyTotalChange = (e) => {
    const totalValue = e.target.value;
    if (price > 0) {
      
      const calculatedAmount = totalValue / price;
      setBuyAmount(calculatedAmount.toFixed(4)); // Update the amount field
    } else {
      setBuyAmount(0);
    }
    setbuyTotal(totalValue); // Update the total field
  };

  const handlebuyamountChange = (e) => {
    let totalValue = e.target.value;
    if (price > 0) {
       totalValue = totalValue.match(/^\d*\.?\d{0,6}$/)
    ? totalValue
    : volume; // Keep the previous value if invalid

      const calculatedAmount = totalValue * price;
      setbuyTotal(calculatedAmount.toFixed(4)); // Update the amount field
    } else {
      setbuyTotal();
    }
    setBuyAmount(totalValue); // Update the total field
  };

  const handlesellamountChange = (e) => {
    let totalValue = e.target.value;
    if (price > 0) {
      totalValue = totalValue.match(/^\d*\.?\d{0,6}$/)
      ? totalValue
      : volume; // Keep the previous value if invalid
      const calculatedAmount = totalValue * price;
      setsellTotal(calculatedAmount.toFixed(4)); // Update the amount field
    } else {
      setsellTotal();
    }
    setSellAmount(totalValue); // Update the total field
  };

  const handlebuypriceChange = (e) => {
    let price = e.target.value;
    price = price.match(/^\d*\.?\d{0,6}$/)
    ? price
    : volume; // Keep the previous value if invalid
    setPrice(price)
      const calculatedAmount = volume * price;
      setbuyTotal(calculatedAmount.toFixed(4)); // Update the amount field
  };

  const handlesellpriceChange = (e) => {
    let price = e.target.value;
    price = price.match(/^\d*\.?\d{0,6}$/)
    ? price
    : volume; // Keep the previous value if invalid
    setSellPrice(price)
      const calculatedAmount = sellAmount * price;
      setsellTotal(calculatedAmount.toFixed(4)); // Update the amount field
  };

  return (
    <div className="buy-sell-container">
      <div className="form-container">
        <h4>Buy {baseCurrency}</h4>
        <form onSubmit={handleBuySubmit}>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingAmount"
              placeholder={`Amount ${baseCurrency}`}
              value={volume}
              step="0.000001"
              onChange={handlebuyamountChange}
              required
            />
            <label htmlFor="floatingAmount">Amount {baseCurrency}</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingPrice"
              placeholder={`Price ${quoteCurrency}`}
              value={price}
              onChange={handlebuypriceChange}
              required
            />
            <label htmlFor="floatingPrice">Price {quoteCurrency}</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingTotal"
              placeholder={`Total ${quoteCurrency}`}
              value={buyTotal}
              onChange={handlebuyTotalChange}
            />
            <label htmlFor="floatingTotal">Total {quoteCurrency}</label>
          </div>

          <p className="balance-info">
            Balance ({quoteCurrency}):{" "}
            {buyBalance ? buyBalance.balance.toFixed(4) : "0"}
          </p>
          <button type="submit" disabled={loading}>
            {loading ? "Processing..." : `Buy ${baseCurrency}`}
          </button>
        </form>
      </div>
      <div className="form-container">
        <h4>Sell {baseCurrency}</h4>
        <form onSubmit={handleSellSubmit}>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingSellAmount"
              placeholder={`Amount ${baseCurrency}`}
              value={sellAmount}
              step="0.000001"
              onChange={handlesellamountChange}
              required
            />
            <label htmlFor="floatingSellAmount">Amount {baseCurrency}</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingSellPrice"
              placeholder={`Price ${quoteCurrency}`}
              value={sellPrice}
              onChange={handlesellpriceChange}
              required
            />
            <label htmlFor="floatingSellPrice">Price {quoteCurrency}</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingSellTotal"
              placeholder={`Total ${quoteCurrency}`}
              value={sellTotal}
              onChange={handlesellTotalChange}
            />
            <label htmlFor="floatingSellTotal">Total {quoteCurrency}</label>
          </div>

          <p className="balance-info">
            Balance ({baseCurrency}):{" "}
            {sellBalance ? sellBalance.balance.toFixed(4) : "0"}
          </p>
          <button type="submit" disabled={loading}>
            {loading ? "Processing..." : `Sell ${baseCurrency}`}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BuySellPage;

import React, { useState, useEffect } from "react";
import "../style/TradingPairDetails.css";
import { fetchTradingPairs } from "../api";
import { useParams, useNavigate } from "react-router-dom";
import {
  subscribeToTradeHistory,
  unsubscribeFromOrderBook,
} from "../socketService";

const TradingPairDetails = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [last24data, setLast24Data] = useState([]);
  const [lowPrice, setLowPrice] = useState(null);
  const [highPrice, setHighPrice] = useState(null);
  const [lastPrice, setLastPrice] = useState(null);
  const [totalVolume, settotalVolume] = useState(null);

  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === symbol && data.tradeHistory) {
        const now = Date.now();
        const last24Hours = 124 * 60 * 60 * 1000;
        // Filter trade history for the last 24 hours
        const filteredData = data.tradeHistory.filter((trade) => {
          const tradeTimestamp = parseInt(trade.trade_date, 10);
          return now - tradeTimestamp <= last24Hours;
        });

        setLast24Data(filteredData);

        // Calculate low, high, and last prices in the last 24 hours
        if (filteredData.length > 0) {
          const prices = filteredData.map((trade) => parseFloat(trade.price));
          const volumes = filteredData.map((trade) => parseFloat(trade.volume));
          const totalVolume = volumes.reduce((sum, volume) => sum + volume, 0);
          setLowPrice(Math.min(...prices));
          setHighPrice(Math.max(...prices));
          setLastPrice(prices[prices.length - 1]); // Last price is from the most recent trade
          settotalVolume(totalVolume);
        } else {
          setLowPrice(0);
          setHighPrice(0);
          setLastPrice(0);
        }
      } else {
        setLast24Data([]);
        setLowPrice(0);
        setHighPrice(0);
        setLastPrice(0);
      }
    };

    subscribeToTradeHistory(symbol, handleTradeHistoryUpdate);

    return () => {
      unsubscribeFromOrderBook(symbol);
    };
  }, [symbol]);

  return (
    <div className="trading-pair-details24">
      <div className="metrics">
        <div>
          Pair: <strong>{symbol}</strong>
        </div>
        <div>
          Last Price: <strong>{lastPrice ? lastPrice : "00"}</strong>
        </div>
        <div>
          Volume: <strong>{totalVolume ? totalVolume.toFixed(0) : "00"}</strong>
        </div>

        <div>
          High: <strong>{highPrice}</strong>
        </div>
        <div>
          Low: <strong>{lowPrice}</strong>
        </div>
      </div>
    </div>
  );
};

export default TradingPairDetails;

import React, {useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/ctskola-0.png';
import '../style/Header.css';
import { AuthContext } from './AuthContext'; // Import AuthContext
import { getnotification } from "../api"; // Adjust the path according to your file structure

const Header = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState("");
  const [showNotificationBar, setShowNotificationBar] = useState(false); // Control visibility
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // Access and update login state from AuthContext
  const [menuOpen, setMenuOpen] = useState(false); // For mobile menu toggle

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // Close menu after navigation on mobile
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove auth token
    setIsLoggedIn(false); // Update login state in AuthContext
    navigate('/'); // Redirect to home page
    setMenuOpen(false); // Close menu on logout
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getnotification();
        if (response.data?.status === "true") {
          setNotifications(response.data?.message || "");
          setShowNotificationBar(true);
        } else {
          setShowNotificationBar(false);
        }
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
        setShowNotificationBar(false);
      }
    };
    fetchNotifications();
  }, []);


  return (
    <>
    <header className="header">
      <div className="header-container">
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => handleNavigation('/')}
        />

        <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <button onClick={() => handleNavigation('/')}>HOME</button>
          <button onClick={() => handleNavigation('/spot/RUBY-INR')}>
            EXCHANGE
          </button>

          {isLoggedIn ? (
            <>
              <button onClick={() => handleNavigation('/wallet')}>WALLET</button>
              <button onClick={() => handleNavigation('/profile')}>
                PROFILE
              </button>
              <button onClick={handleLogout}>LOGOUT</button>
            </>
          ) : (
            <>
              <button onClick={() => handleNavigation('/login')}>LOGIN</button>
              <button onClick={() => handleNavigation('/register')}>
                REGISTER
              </button>
            </>
          )}
        </nav>

        <div
          className="hamburger-menu"
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
     {/* Notification Bar */}
     {showNotificationBar && (
      <div className="notification-bar">
        <div className="notification-content">
          <span className="notification-item">{notifications}</span>
        </div>
      </div>
    )}
  </>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import "../style/TradingPairDetails.css";
import { fetchTradingPairs } from "../api";
import { useParams, useNavigate } from "react-router-dom";

const TradingPairDetails = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();

  const [pairs, setPairs] = useState([]); // Store trading pairs
  const [selectedCurrency, setSelectedCurrency] = useState("RUBY"); // Default currency
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch trading pairs
  useEffect(() => {
    const fetchPairs = async () => {
      setIsLoading(true);
      try {
        const response = await fetchTradingPairs();
        if (response) {
          setPairs(response || []);
        }
      } catch (error) {
        console.error("Error fetching trading pairs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPairs();
  }, []);

  // Handle pair selection to navigate to a specific trading pair
  const handlePairClick = (pair) => {
    setDropdownVisible(false);
    navigate(`/spot/${pair}`);
  };

  // Handle currency selection
  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency);
  };

  return (
    <div className="trading-pair-details">
      {/* Currency Selection Section */}
      <div className="currency-card-container">
        {["RUBY", "INR", "TRON"].map((currency) => (
          <div
            key={currency}
            className={`currency-card ${
              selectedCurrency === currency ? "selected" : ""
            }`}
            onClick={() => handleCurrencyClick(currency)}
          >
            {currency}
          </div>
        ))}
      </div>

      {/* Dropdown for pairs */}
      <div className="dropdown">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          (pairs[selectedCurrency] || []).map((pair, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handlePairClick(`${pair.symbol}-${selectedCurrency}`)}
            >
              <img
                src={pair.icon}
                alt={pair.market}
                style={{ width: "20px", marginRight: "10px" }}
              />
              <span className="pair-symbol">
                {pair.symbol}-{selectedCurrency}
              </span>
              <span className="dropdown-price">{pair.price}</span>
             
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TradingPairDetails;

import React, { useState, useEffect } from "react";
import "../style/HomePage.css";
import tradingImg from "../img/trading-img.png";
import { fetchTradingPairs } from "../api"; // Adjust the path according to your file structure
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const [tradingPairs, setTradingPairs] = useState([]);
  const navigate = useNavigate();

  // Fetch trading pairs when the component mounts
  useEffect(() => {
    const getTradingPairs = async () => {
      try {
        const data = await fetchTradingPairs();
        setTradingPairs(data.RUBY);
      } catch (error) {
        console.error("Error fetching trading pairs:", error);
      }
    };
    getTradingPairs();
  }, []);

  const handleTrade = (action, pair) => {
    if (action === "buy") {
      // Add your buy logic here
    } else if (action === "sell") {
      // Add your sell logic here
    }
  };

  return (
    <>
      <div className="home-page">
        <div className="content-container">
          <div className="text-container">
            <h1 className="headline">BUY & SELL CRYPTO ON CTSKOLA</h1>
            <p className="subheadline">
              The Highly Efficient exchange for stable and secure Trade.
              <br />
              Trade Bitcoin, Ethereum, BAT, and 150+ cryptos in seconds on our
              simple and ultra-secure crypto exchange.
            </p>
            <button className="start-trading-button">Start Trading Now</button>
          </div>
          <div className="image-container">
            <img
              src={tradingImg}
              alt="Trading Illustration"
              className="trading-img"
            />
          </div>
        </div>
      </div>

      <div className="trading-grid">
        {tradingPairs.length > 0 ? (
          tradingPairs.slice(0, 4).map((pair, index) => (
            <div key={index} className="trading-item">
              <div className="pair-container">
                <div className="pair">{pair.symbol}/RUBY</div>
                <div className="percentage">Price {pair.ruby_price}</div>
              </div>
            </div>
          ))
        ) : (
          <div>Loading trading pairs...</div>
        )}
      </div>

      <div className="Market-heading">Market Trend</div>

      {tradingPairs.length > 0 ? (
        <table className="trading-table">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Price (RUBY)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tradingPairs.slice(1, 6).map((pair, index) => (
              <tr key={index}>
                <td className="icon-and-symbol">
                  <img
                    src={pair.icon}
                    alt={`${pair.symbol} icon`}
                    className="currency-icon"
                  />
                  {pair.symbol}
                </td>
                <td>{pair.name}</td>
                <td>{pair.ruby_price}</td>
                <td>
                  <button
                    className="trade-button buy-button"
                    onClick={() => {
                      navigate(`/spot/${pair.symbol}-RUBY`); // Replace '/spot' with your Spot page route
                    }}
                  >
                    Trade
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>Loading trading pairs...</div>
      )}
    </>
  );
};

export default HomePage;

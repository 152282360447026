import React, { useState, useEffect } from "react";
import "../style/TradeHistory.css"; // Ensure this file exists and has proper styles
import {
  subscribeToTradeHistory,
  unsubscribeFromOrderBook,
} from "../socketService";
import { useParams } from "react-router-dom";

const TradeHistory = () => {
  const { symbol } = useParams(); // Extract the trading pair symbol from the route
  const [tradeHistory, setTradeHistory] = useState([]);

  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.tradeHistory) {
        // Create a copy of the array and reverse it
        let reversedData = [...data.tradeHistory].reverse();    
        // Check symbol and update state
        if (data.symbol === symbol) {
          setTradeHistory(reversedData);
        } else {
          setTradeHistory([]);
        }
      } else {
        setTradeHistory([]);
      }
    };
    
    // Subscribe to trade history updates for the current symbol
    subscribeToTradeHistory(symbol, handleTradeHistoryUpdate);

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      unsubscribeFromOrderBook(symbol);
    };
  }, [symbol]);

  return (
    <div className="trade-history">
      <h3>Trade History</h3>
      <div className="table-container-trade">
        <table>
          <thead>
            <tr>
              <th>Price</th>
              <th>Volume</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {tradeHistory.length > 0 ? (
              tradeHistory.map((trade, index) => (
                <tr key={index}>
                  <td>{trade.price}</td>
                  <td>{trade.volume}</td>
                  <td>
                    {new Date(trade.trade_date * 1000).toLocaleTimeString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No trade history available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TradeHistory;

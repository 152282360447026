import React, { useState } from "react";
import TradingPairDetails from "./TradingPairDetails";
import OrderBook from "./OrderBook";
import TradeHistory from "./TradeHistory";
import Chart from "./Chart";
import BuySellPage from "./BuySellPage";
import OrdersPage from "./OrdersPage";
import TradingPair from "./TradingPair";

import "../style/TradePage.css"; // Add custom styling if needed

const TradePage = () => {
  const [selectedOrder, setSelectedOrder] = useState(null); // Shared state for selected data

  // Callback to handle selected data from OrderBook
  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
  };

  return (
    <div className="trade-page">
      <TradingPairDetails />

      <div className="trade-content">
        <div className="chart-and-orderbook">
          <div>
            {" "}
            <TradingPair />
            <div className="TradeHistory">
              <TradeHistory />
            </div>
          </div>
          <div className="chart-container">
            <Chart />
            <div className="BuySellPage-container">
              <BuySellPage selectedOrder={selectedOrder} />
            </div>
          </div>
          <div className="orderbook-container">
            <OrderBook onOrderSelect={handleOrderSelect} />
           
          </div>
          <div className="BuySellPage-container-mobile">
              <BuySellPage selectedOrder={selectedOrder} />
            </div>
          <div className="TradeHistory-mobile">
            <TradeHistory />
          </div>
        </div>
        <div>
          <OrdersPage />
        </div>
      </div>
    </div>
  );
};

export default TradePage;

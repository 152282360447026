// api.js
import axios from "axios";

const API_URL = "https://testapi.ctskola.io/api/";

export const fetchTradingPairs = async () => {
  try {
    const response = await axios.get(API_URL + 'getpair');
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching trading pairs:", error);
    throw error; // Re-throw the error for handling in the component
  }
};


export const registerUser = async (userData) => {
  console.log("userData",userData)
  try {
    const response = await axios.post(API_URL + 'registerUser', userData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await axios.post(API_URL + 'loginUser', userData);
    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};

export const getwallet = async () => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'getwallet', {}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const createwallets = async (symbol) => {
  try {
    let payload ={
      symbol:symbol
    }
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'createwallets', payload, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};


export const sellOrder = async (symbol, raw_price, volume) => {
  try {
    const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

    const response = await axios.post(API_URL + 'sell', {
      symbol,
      raw_price,
      volume,
    }, // Corrected: Add a comma after the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      });
    return response.data; // Return the response data if needed
  } catch (error) {
    console.error('Error selling order:', error);
    throw error; // Rethrow the error for handling in the component
  }
};


export const buyOrder = async (symbol, raw_price, volume) => {
  const token = localStorage.getItem('authToken'); // Assuming you're storing the token in localStorage

  try {
    const response = await axios.post(
      API_URL + 'buy', // API endpoint
      {
        symbol,
        raw_price,
        volume,
      }, // Corrected: Add a comma after the request body
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',  // Ensure content type is set correctly
        },
      }
    );
    return response.data; // Return the response data if needed
  } catch (error) {
    console.error('Error placing buy order:', error);
    throw error; // Rethrow the error for handling in the component
  }
};

export const getuserorder = async () => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'getuserorder', {}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const cancelorder = async (orderId, ordertype) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'cancelorder', {orderId, ordertype}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const getbalance = async (symbol) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'getbalance', {symbol}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};


export const gettransaction = async () => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'gettransaction', {}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};


export const setPersonalInfo = async (kycInfo) => {
  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'setPersonalInfo', kycInfo, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const verifyPanCard = async (panNumber) => {
  let payload = {
    pan_no:panNumber
  }

  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'verifiePanCard', payload, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const sendAadharOtp = async (AadharNumber) => {
  console.log("verifiePanCard",AadharNumber)

  let payload = {
    id_number:AadharNumber
  }
  console.log("verifiePanCard",payload)

  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'sendAadharOtp', payload, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const verifyAadharCard = async (AadharNumber,otp,client_id) => {

  let payload = {
    id_number:AadharNumber,
    otp:otp,
    client_id:client_id
  }
  console.log("verifiePanCard",payload)

  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'verifieAadharcard', payload, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const getInfo = async () => {


  try {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'getInfo', {}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const sendotp = async () => {


  try {
  
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'sendotp', {}, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const verifyUserEmail = async (otp) => {


  try {
    let payload = {
      otp:otp,
   
    }
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'verifyUserEmail', payload, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};


export const withdraw = async (withdrawalData) => {

  try {

    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Set up the Authorization header with the token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Make the API call with the Authorization header
    const response = await axios.post(API_URL + 'withdraw', withdrawalData, { headers });
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const upload = async (formData) => {

  try {

    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // Check if the token exists
    if (!token) {
      throw new Error("No token found, please login.");
    }

    // Make the API call with the Authorization header

    const response = await fetch("http://143.244.135.250:3006/api/upload", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    // Return the response data
    return response;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const getprice = async (symbol) => {
  try {

 
    // Make the API call with the Authorization header
    const response = await axios.get(API_URL + `getprice/${symbol}`, {},);
    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const getnotification = async () => {
  try {
    const response = await axios.get(API_URL + 'getnotification');
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching trading pairs:", error);
    throw error; // Re-throw the error for handling in the component
  }
};
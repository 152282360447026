import React, { useState, useEffect } from "react";
import { gettransaction } from "../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/WalletPage.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const WithdrawalHistory = () => {
  const [walletData, setWalletData] = useState([]);
  const [error, setError] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await gettransaction();
        setWalletData(response.data.withdraw_historys);
      } catch (err) {
        setError("Error fetching wallet data.");
        toast.error("Error fetching wallet data.");
      }
    };

    fetchWallet();
  }, []);

  return (
    <div className="wallet-container">
      <div className="balance-card">
        <div className="balance-card-header">
          <button className="sidebar-button" onClick={toggleSidebar}>
            Sidebar
          </button>
          <p className="balance-amount" id="totalBalance">
            Withdrawal History
          </p>
        </div>

        {isSidebarOpen && (
          <div className="sidebar">
            <button
              className="sidebar-item"
              onClick={() => navigate("/Deposithistory")}
            >
              Deposit History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/INRDeposithistory")}
            >
              INR Deposit History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/WithdrawalHistory")}
            >
              Withdrawal History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/OpenOrder")}
            >
              Open Order
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/CloseOrder")}
            >
              Close Order
            </button>{" "}
          </div>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}

      {walletData.length > 0 ? (
        <div className="table-container">
          <table className="wallet-table">
            <thead>
              <tr>
                <th></th>
                <th>symbol</th>
                <th>Balance</th>
                <th>Hash</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {walletData.map((wallet) => (
                <React.Fragment key={wallet._id}>
                  <tr>
                    <td>
                      <img
                        src={wallet.icon}
                        alt={wallet.symbol}
                        className="currency-icon"
                      />
                    </td>
                    <td>{wallet.symbol}</td>
                    <td>{parseFloat(wallet.amount).toFixed(6)}</td>
                    <td
                      className="hash-cell"
                      onClick={() => {
                        navigator.clipboard.writeText(wallet.transection_id);
                        toast.success("Hash copied to clipboard!");
                      }}
                      title="Click to copy"
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      {wallet.transection_id
                        ? `${wallet.transection_id.slice(
                            0,
                            6
                          )}...${wallet.transection_id.slice(-6)}`
                        : "N/A"}
                    </td>
                    <td>
                      {wallet.status === 0
                        ? "Pending"
                        : wallet.status === 1
                        ? "Success"
                        : wallet.status === 2
                        ? "Cancel"
                        : "Unknown"}
                    </td>

                    <td>{new Date(wallet.createdAt).toLocaleString()}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No wallet data available.</p>
      )}
    </div>
  );
};

export default WithdrawalHistory;
